import { API_ENDPOINT } from "../config/constant";
import { request } from "./../config/request";
import noimagelawyer from "./../images/lawyer.png";
import noimagefirm from "./../images/firm.png";
import noimagebook from "./../images/book.png";
import Catalog from "../Catalog";

export const StoreService = {
  async fetchStoreItem(store, id, slug) {
    let defaultImg = null;
    let storename = "books";
    switch (store) {
      case "lawyers":
        defaultImg = noimagelawyer;
        storename = "lawyers";
        break;

      case "firms":
        defaultImg = noimagefirm;
        storename = "lawyers";
        break;

      case "books":
        defaultImg = noimagebook;
        break;
      default:
        break;
    }
    const storeItems = await request
      .get(
        `/api/${storename}/${id}?populate[PrimaryImageId]=true&populate[contact]=true&populate[Property1]=true&populate[Property2]=true&populate[PropertyList1]=true&populate[PropertyList2]=true&populate[PropertyList3]=true&populate[SecondaryImageIds]=true`
      )
      .then((response) => {
        if (response.status === 200) { 
          const {
            id,
            attributes: {
              PrimaryImageId,
              SecondaryImageIds,
              Property1,
              Property2,
              PropertyList1,
              PropertyList2,
              PropertyList3,
              ...storeItems
            },
          } = response.data.data; 
          let gallery = [];
          for (const key in SecondaryImageIds.data) {
            if (
              Object.prototype.hasOwnProperty.call(
                SecondaryImageIds.data,
                key
              )
            ) {
              const image = SecondaryImageIds.data[key];
              gallery.push({
                id: image.id,
                image: API_ENDPOINT + image.attributes.url,
                ...image.attributes,
              });
            }
          }
          return {
            id: id,
            Property1: Property1.data.map((v) => v.id),
            Property2: Property2.data.map((v) => v.id),
            PropertyList1: PropertyList1.data.map((v) => v.id),
            PropertyList2: PropertyList2.data.map((v) => v.id),
            PropertyList3: PropertyList3.data.map((v) => v.id),
            image: PrimaryImageId.data
              ? API_ENDPOINT +
                PrimaryImageId.data.attributes.url
              : defaultImg,
            ...storeItems,
            gallery: gallery,
          };  
        } else {
          return {};
        }
      })
      .catch((error) => { 
        return {};
      });
    return storeItems;
  },
  async fetchStore(store, filter) {
    let defaultImg = null;
    let idx = 0;
    let catlogurl = `/api/books?populate[PrimaryImageId]=true`;
    switch (store) {
      case "lawyers":
        defaultImg = noimagelawyer;
        catlogurl = `/api/lawyers?filters[$and][${idx}][SubCategory2][$eq]=0&populate[PrimaryImageId]=true`;
        idx++;
        break;

      case "firms":
        defaultImg = noimagefirm;
        catlogurl = `/api/lawyers?filters[$and][${idx}][SubCategory2][$eq]=1&populate[PrimaryImageId]=true`;
        idx++;
        break;

      case "books":
        defaultImg = noimagebook;
        break;
      default:
        break;
    }
    for (const key in filter) {
      if (
        Object.prototype.hasOwnProperty.call(filter, key) &&
        filter[key].length > 0
      ) {
        filter[key].forEach((v, k) => {
          if (key === "SubTitle"||key === "Category") {
            catlogurl += `&filters[$and][${idx}][$or][${k}][${key}][$contains]=${v}`;
          } else {
            catlogurl += `&filters[$and][${idx}][$or][${k}][${key}][id][$contains]=${v}`;
          }
        });
        idx++;
      }
    }
    const storeItems = await request
      .get(catlogurl)
      .then((response) => {
        let proData = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        if (response.status === 200) {
          proData.pagination = response.data.meta.pagination;
          for (let pro in response.data.data) {
            const storeItems = response.data.data[pro];
            proData.data.push({
              id: storeItems.id,
              image: storeItems.attributes.PrimaryImageId.data
                ? API_ENDPOINT +
                  storeItems.attributes.PrimaryImageId.data.attributes.url
                : defaultImg,
              ...storeItems.attributes,
            });
          }
        }
        return proData;
      })
      .catch((error) => {
        return {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
      });
    return storeItems;
  },
  async createOrder(order, settings) {
    const orders = await request
      .post("/api/orders", { data: order })
      .then((response) => {
        let ordersData = {};
        if (response.data) {
          ordersData = response.data;
        }
        return ordersData;
      })
      .catch((error) => {
        return {};
      });
    if (orders.id) {
      const message = `
          <table>
              <tr>
                  <td>User</td>
                  <td>${Catalog.Session.user.email}</td>
              </tr>
              <tr>
                  <td>Store</td>
                  <td>${orders.store_id}</td>
              </tr>
              <tr>
                  <td>Date</td>
                  <td>${orders.purchase_date}</td>
              </tr>
              <tr>
                  <td>Item</td>
                  <td>${orders.item_detail.title}</td>
              </tr> 
          </table> 
          `;
      const messageText = `User :${Catalog.Session.user?.email ?? ""} ,Store :${
        orders.store_id
      } ,Date :${orders.purchase_dat} ,Item :${orders.item_detail.title} `;
      const senemail = settings.contact_email;
      const sensubject = "Order - " + orders.item_detail.title;

      await request.post("/api/email", {
        to: senemail,
        subject: sensubject,
        text: messageText,
        html: message,
      });
    }
    return orders;
  },
  async fetchAccount(id) {
    const account = await request
      .get(`/api/users/${id}?populate[role]=true`)
      .then((response) => {
        let userData = {};
        if (response.data) {
          userData = response.data;
        }
        return userData;
      })
      .catch((error) => {
        return {};
      });
    return account;
  },
  async fetchOrder(id) {
    const orders = await request
      .get(`/api/orders?filters[user_id][id][$eq]=${id}`)
      .then((response) => {
        let orderData = {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
        if (response.status === 200) {
          orderData.pagination = response.data.meta.pagination;
          for (let pro in response.data.data) {
            const orderItems = response.data.data[pro];
            orderData.data.push({
              id: orderItems.id,
              ...orderItems.attributes,
            });
          }
        }
        return orderData;
      })
      .catch((error) => {
        return {
          data: [],
          pagination: {
            page: 1,
            pageSize: 25,
            pageCount: 1,
            total: 0,
          },
        };
      });
    return orders;
  },
  async fetchLawyer(id) {
    const account = await request
      .get(
        `/api/lawyers?filters[users_permissions_user][id][$eq]=${id}&populate[PrimaryImageId]=true&populate[contact]=true&populate[Property1]=true&populate[Property2]=true&populate[Property3]=true&populate[PropertyList1]=true&populate[PropertyList2]=true&populate[PropertyList3]=true&populate[SecondaryImageIds]=true`
      )
      .then((response) => {
        let userData = {};
        if (response.data) {
          const {
            id,
            attributes: {
              PrimaryImageId,
              SecondaryImageIds,
              Property1,
              Property2,
              Property3,
              PropertyList1,
              PropertyList2,
              PropertyList3,
              ...attributes
            },
          } = response.data.data[0];
          userData = {
            id: id,
            Property1: Property1.data.map((v) => v.id),
            Property2: Property2.data.map((v) => v.id),
            Property3: Property3.data.map((v) => v.id),
            PropertyList1: PropertyList1.data.map((v) => v.id),
            PropertyList2: PropertyList2.data.map((v) => v.id),
            PropertyList3: PropertyList3.data.map((v) => v.id),
            image: PrimaryImageId.data
              ? API_ENDPOINT + PrimaryImageId.data.attributes.url
              : noimagelawyer,
            PrimaryImageId: PrimaryImageId,
            SecondaryImageIds: SecondaryImageIds,
            ...attributes,
            gallery: (SecondaryImageIds.data || []).map((img) => {
              return {
                fileUrl: API_ENDPOINT + img.attributes.url,
                fileName: img.attributes.name,
                fileSize: img.attributes.size,
                fileMime: img.attributes.mime,
              };
            }),
            imagedata: PrimaryImageId.data
              ? {
                  fileUrl: API_ENDPOINT + PrimaryImageId.data.attributes.url,
                  fileName: PrimaryImageId.data.attributes.name,
                  fileSize: PrimaryImageId.data.attributes.size,
                  fileMime: PrimaryImageId.data.attributes.mime,
                }
              : null,
          };
        }
        return userData;
      })
      .catch((error) => {
        // console.error(error)
        return {};
      });
    return account;
  },
  async fetchFirm(id) {
    const account = await request
      .get(
        `/api/lawyers?filters[users_permissions_user][id][$eq]=${id}&populate[PrimaryImageId]=true&populate[contact]=true`
      )
      .then((response) => {
        let userData = {};
        if (response.data) {
          userData = {
            id: response.data.data[0].id,
            image: response.data.data[0].attributes.PrimaryImageId.data
              ? API_ENDPOINT +
                response.data.data[0].attributes.PrimaryImageId.data.attributes
                  .url
              : noimagefirm,
            ...response.data.data[0].attributes,
          };
        }
        return userData;
      })
      .catch((error) => {
        return {};
      });
    return account;
  },
  async updateLawyer(formdata, id) {
    const account = await request
      .put(`/api/lawyers/${id}`, { data: formdata })
      .then((response) => {
        let userData = {};
        if (response.status === 200) {
          userData = {
            id: response.data.data.id,
            ...response.data.data.attributes,
          };
        }
        return userData;
      })
      .catch((error) => {
        return {};
      });
    return account;
  },
  async updateFirm(formdata, id) {
    const account = await request
      .put(`/api/lawyers/${id}`, { data: formdata })
      .then((response) => {
        let userData = {};
        if (response.status === 200) {
          userData = {
            id: response.data.data.id,
            ...response.data.data.attributes,
          };
        }
        return userData;
      })
      .catch((error) => {
        return {};
      });
    return account;
  },
  async fetchFilterIds(store){
    let availableFilterData= { 
      PropertyList1:[],
      PropertyList2:[],
      PropertyList3:[],
      Category:[],
      SubTitle:[],
    }
    if(store==="books"){
      availableFilterData.PropertyList1 = await request
        .get(`/api/book-tags?filters[catalog_books][id][$notNull]=true&fields=id`)
        .then((response) => {
          let userData = [];
          if (response.status===200) {
            userData = (response.data.data||[]).map((itm)=>itm.id);
          }
          return userData;
        }).catch((error) => {  return [];  });

      availableFilterData.SubTitle = [...new Set(await request
      .get(`/api/books?fields=SubTitle`)
      .then((response) => {
        let userData = [];
        if (response.status===200) {
          userData = (response.data.data||[]).map((itm)=>itm.attributes.SubTitle);
        }
        return userData;
      }).catch((error) => {  return [];  }))];

      availableFilterData.Category = [...new Set(await request
        .get(`/api/catelog-categories?filters[catalog_books][id][$notNull]=true&fields=id`)
        .then((response) => {
          let userData = [];
          if (response.status===200) {
            userData = (response.data.data||[]).map((itm)=>itm.id);
          }
          return userData;
        }).catch((error) => {  return [];  }))];
    }else{
      const aidx=store==="lawyers"?0:1;
      availableFilterData.PropertyList1 = await request
        .get(`/api/specializations?filters[catalog_lawyers][SubCategory2][$eq]=${aidx}&fields=id`)
        .then((response) => {
          let userData = [];
          if (response.status===200) { 
            userData = (response.data.data||[]).map((itm)=>itm.id);
          }
          return userData;
        }).catch((error) => {  return [];  });

      availableFilterData.PropertyList2 = await request
      .get(`/api/courts?filters[catalog_lawyers][SubCategory2][$eq]=${aidx}&fields=id`)
      .then((response) => {
        let userData = [];
        if (response.status===200) {
          userData = (response.data.data||[]).map((itm)=>itm.id);
        }
        return userData;
      }).catch((error) => {  return [];  });


      availableFilterData.PropertyList3 = await request
      .get(`/api/languages?filters[catalog_lawyers][SubCategory2][$eq]=${aidx}&fields=id`)
      .then((response) => {
        let userData = [];
        if (response.status===200) {
          userData = (response.data.data||[]).map((itm)=>itm.id);
        }
        return userData;
      }).catch((error) => {  return [];  });
    } 
    return availableFilterData;
  } 
};
