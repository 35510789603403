import React, { Component } from "react";
import { AppContext } from "../config/common";
import Catalog from "../Catalog";
import { Navigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { StoreService } from "../services/store"; 
import FormCheckGroup from "../widgets/FormCheckGroup";
import { BookService } from "../services/book";
class StoreController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      storeItems: {
        data: [],
        pagination: {
          page: 1,
          pageSize: 25,
          pageCount: 1,
          total: 0,
        },
      },
      filterData: { 
        PropertyList1:[],
        PropertyList2:[],
        PropertyList3:[],
        Category:[],
        SubTitle:[],
      },
      availableFilterData: { 
        PropertyList1:[],
        PropertyList2:[],
        PropertyList3:[],
        Category:[],
        SubTitle:[],
      },
      authors:[],
      storeName: "",
      isloaded:false,
      isShowFilter:false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0); 
    this.intervalId=setInterval(this.refreshlist, 1000); 
    if(this.state.store==="books"){
      this.fetchAuthors();
    }
    this.fetchStore();
    this.fetchFilterIds();
  }
  componentDidUpdate() {
    if (this.state.store !== this.state.storeName) {
      this.fetchStore();
    }
  }
 
  componentWillUnmount() { 
    clearInterval(this.intervalId);
  }
  refreshlist=async ()=>{ 
    if(!this.state.isloaded&&Catalog.isLoad){
      await Catalog.refreshConfig();
    }
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }

  fetchAuthors=async ()=>{
    const authors=await BookService.fetchAuthors();
    this.setState({ authors }); 
  }

  fetchFilterIds=async ()=>{
    const storeName = this.state.store;
    const availableFilterData=await StoreService.fetchFilterIds(storeName);
    this.setState({ availableFilterData });  
  }

  fetchStore=async (filterData={})=> {
    const storeName = this.state.store;
    this.setState({ storeName });  
    const storeItems = await StoreService.fetchStore(storeName,filterData); 
    this.setState({ storeItems });
  }
  handleChange=(e)=>{
    const {name,value,checked} = e.target;
    let filterData = this.state.filterData; 
    if(checked){
      filterData[name]=[...filterData[name],value];
    }else{
      filterData[name]=filterData[name].filter((v,k)=>v!==value)
    }
    const storeItems ={
      data: [],
      pagination: {
        page: 1,
        pageSize: 25,
        pageCount: 1,
        total: 0,
      },
    };
    this.setState({ filterData ,storeItems })
    this.fetchStore(filterData);
  }
  render() {
    let catalog = Catalog.Book;
    switch (this.state.store) {
      case "books":
        catalog = Catalog.Book;
        break;
      case "lawyers":
        catalog = Catalog.Lawyer;
        break;
      case "firms":
        catalog = Catalog.Firm;
        break;

      default:
        return (
          <>
            <Navigate replace to="/404" />
          </>
        );
    }  
    return (
      <>
        <div className="store-wrap">
          <div className="row">
            <div className="col-md-3"  >
              <Card className={this.state.isShowFilter?"mobile-fade active":"mobile-fade"}>
                <Card.Header>
                  <span>Filters</span>
                  <button className="mobile-fade-btn" onClick={()=>{
                    const isShowFilter=!this.state.isShowFilter;
                    this.setState({isShowFilter});
                  }}></button>
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    {this.state.store==="books"? ( <>
                      {catalog.Config.Category&&this.state.availableFilterData.Category.length>0&&(<div className="col-md-12"> 
                        <FormCheckGroup
                          id={"filter-book"}
                          title={catalog.Profile.Category}
                          value={this.state.filterData?.Category}
                          options={catalog.Config.Category.filter((itm)=>(this.state.availableFilterData.Category||[]).includes(itm.value))}
                          name={"Category"}
                          handle={this.handleChange}
                        />
                      </div>)}

                    {catalog.Config.SubTitle&&this.state.availableFilterData.SubTitle.length>0&&(<div className="col-md-12"> 
                      <FormCheckGroup
                          id={"filter-author"}
                        title={catalog.Profile.SubTitle}
                        value={this.state.filterData?.SubTitle}
                        options={this.state.authors.filter((itm)=>(this.state.availableFilterData.SubTitle||[]).includes(itm.value))}
                        name={"SubTitle"}
                        handle={this.handleChange}
                      />
                    </div>)}
                      </>
                    ) : (
                      <>

                        {catalog.Config.PropertyList3&&this.state.availableFilterData.PropertyList3.length>0&&(<div className="col-md-12"> 
                        <FormCheckGroup
                          id={"filter-l3"}
                          title={catalog.Profile.PropertyList3}
                          value={this.state.filterData?.PropertyList3}
                          options={catalog.Config.PropertyList3.filter((itm)=>(this.state.availableFilterData.PropertyList3||[]).includes(itm.value))}
                          name={"PropertyList3"}
                          handle={this.handleChange}
                        />
                      </div>)}
                      {catalog.Config.PropertyList2&&this.state.availableFilterData.PropertyList2.length>0&&(<div className="col-md-12"> 
                        <FormCheckGroup
                          id={"filter-l2"}
                          title={catalog.Profile.PropertyList2}
                          value={this.state.filterData?.PropertyList2}
                          options={catalog.Config.PropertyList2.filter((itm)=>(this.state.availableFilterData.PropertyList2||[]).includes(itm.value))}
                          name={"PropertyList2"}
                          handle={this.handleChange}
                        />
                      </div>)}
                      
                      </>
                    )}


                    {catalog.Config.PropertyList1&&this.state.availableFilterData.PropertyList1.length>0&&(<div className="col-md-12"> 
                      <FormCheckGroup
                          id={"filter-l1"}
                        title={catalog.Profile.PropertyList1}
                        value={this.state.filterData?.PropertyList1}
                        options={catalog.Config.PropertyList1.filter((itm)=>(this.state.availableFilterData.PropertyList1||[]).includes(itm.value))}
                        name={"PropertyList1"}
                        handle={this.handleChange}
                      />
                    </div>)}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-9">
              <Card>
                <Card.Header>
                  <span>
                    Showing Products({this.state.storeItems.pagination.total})
                  </span>
                </Card.Header>
                <Card.Body>
                  <div className="store-items">
                    {this.state.storeItems.data.map((storeItem, index) => {
                      return (
                        <catalog.Card
                          key={index}
                          storeItem={storeItem}
                        ></catalog.Card>
                      );
                    })}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default StoreController;
