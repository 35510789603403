import React, { Component } from "react";
import { AppContext } from "../config/common";
import { StoreService } from "../services/store";
import Catalog from "../Catalog";
import { Link, Navigate } from "react-router-dom";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";

class StoreItemDetailController extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      storeItem: {},
      storeName: "",
      storeId: "",
      storeSlug: "",
      loaded: false,
      modal: false,
      isSubmit: false,
      success: null,
      error: null,
      currentimage: null,
      currentImageIndex: 0,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...props,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchStoreItem();
    this.intervalId=setInterval(this.refreshlist, 1000); 
  }

  componentDidUpdate() {
    if (
      this.state.store !== this.state.storeName ||
      this.state.id !== this.state.storeId ||
      this.state.slug !== this.state.storeSlug
    ) {
      this.fetchStoreItem();
    }
  } 
 
  componentWillUnmount() { 
    clearInterval(this.intervalId);
  }
  refreshlist=async ()=>{  
    const isloaded=Catalog.isLoad;
    this.setState({isloaded})
  }
  handleClose = () => {
    const modal = false;
    this.setState({ modal });
  };
  ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  formatDateYMD = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  handleShow = () => {
    const modal = true;
    this.setState({ modal });
  };
  handleSubmit = async () => {
    let isSubmit = true;
    this.setState({ isSubmit });
    const settings = this.context;
    const orderdate = new Date();
    const expdate = new Date();
    expdate.setMonth(expdate.getMonth() + 1);
    const order = await StoreService.createOrder(
      {
        user_id: Catalog.Session.user.id,
        store_id: this.ucfirst(this.state.store),
        item_id: this.state.storeItem.id.toString(),
        purchase_date: this.formatDateYMD(orderdate),
        expiry_date: this.formatDateYMD(expdate),
        item_detail: this.state.storeItem,
        Price: this.state.storeItem.Price,
      },
      settings
    );
    let success,
      error = null;
    if (order.id || (order.data && order.data.id)) {
      success = "Order was placed";
    } else {
      error = "Error: plese try again later ";
    }

    const modal = false;
    isSubmit = false;
    this.setState({ modal, isSubmit, success, error });
  };

  fetchStoreItem = async () => {
    const storeName = this.state.store;
    const storeId = this.state.id;
    const storeSlug = this.state.slug;
    let loaded = false;
    this.setState({ storeName, storeId, storeSlug, loaded });
    const storeItem = await StoreService.fetchStoreItem(
      storeName,
      storeId,
      storeSlug
    );
    loaded = true;
    const currentImageIndex = 0;
    let currentimage = storeItem.image;
    if (this.state.store !== "books" && storeItem.gallery.length > 0) {
      currentimage = storeItem.gallery[0].image;
    }
    this.setState({ storeItem, loaded, currentimage, currentImageIndex });
  };
  handleChangeCurrentImage = (e) => {
    const { src: currentimage } = e.target;
    let currentImageIndex = 0;
    for (let index = 0; index < this.state.storeItem.gallery.length; index++) {
      if (this.state.storeItem.gallery[index].image === currentimage) {
        currentImageIndex = index + 1;
      }
    }
    this.setState({ currentimage, currentImageIndex });
  };
  render() {
    let catalog = Catalog.Book;
    switch (this.state.store) {
      case "books":
        catalog = Catalog.Book;
        break;
      case "lawyers":
        catalog = Catalog.Lawyer;
        break;
      case "firms":
        catalog = Catalog.Firm;
        break;

      default:
        return (
          <>
            <Navigate replace to="/404" />
          </>
        );
    }
    if (this.state.loaded === true) {
      if (!this.state.storeItem.id) {
        return (
          <div className="store-wrap">
            <div className="top-action">
              <Link to={"/catalogs/" + this.state.store}> &lt; Back</Link>
            </div>
            <div className="store-item">
              <div className="store-item-info">
                <div className="store-item-title">
                  <span className="title">Item Not Found</span>
                </div>
              </div>
            </div>
          </div>
        );
      } 
      return (
        <div className="store-wrap">
          {this.state.error && (
            <>
              <Alert variant="danger" dismissible>
                <p>{this.state.error}</p>
              </Alert>
            </>
          )}
          {this.state.success && (
            <>
              <Alert variant="success" dismissible>
                <p>{this.state.success}</p>
              </Alert>
            </>
          )}
          <div className="top-action">
            <Link to={"/catalogs/" + this.state.store}> &lt; Back</Link>
          </div>
          <div className="store-item-detail">
            {this.state.store === "books" && (
              <div className="store-item-avathar">
                <div className="store-item-main">
                  <TransitionGroup>
                    <CSSTransition
                      key={this.state.currentImageIndex}
                      timeout={500}
                      classNames="fade"
                    >
                      <img
                        src={this.state.currentimage}
                        alt={this.state.storeItem.Title}
                      />
                    </CSSTransition>
                  </TransitionGroup>
                </div>
                {this.state.storeItem.gallery.length > 0 && (
                  <div className="store-item-gallery">
                    <div className="store-gallery-item">
                      <img
                        src={this.state.storeItem.image}
                        currentimage={this.state.storeItem.image}
                        onClick={this.handleChangeCurrentImage}
                        alt={this.state.storeItem.Title}
                      />
                    </div>
                    {this.state.storeItem.gallery.map((v, i) => {
                      return (
                        <div className="store-gallery-item" key={i}>
                          <img
                            src={v.image}
                            currentimage={v.image}
                            onClick={this.handleChangeCurrentImage}
                            alt={this.state.storeItem.Title}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            <div className="store-item-info">
              <div className="store-item-title">
                {this.state.store !== "books" && (
                  <div className="avathar">
                    <img
                      src={this.state.storeItem.image}
                      alt={this.state.storeItem.Title}
                    />
                  </div>
                )}
                <div className="title">{this.state.storeItem.Title}</div>
                <div className="subtitle">
                  {this.state.store === "books" && <>By </>}{" "}
                  {this.state.storeItem.SubTitle}
                </div>
              </div>
              <div className="store-item-detail">
                {this.state.store === "books" && (
                  <p>{this.state.storeItem.Category}</p>
                )}
                <table>
                  <tbody>
                    {Catalog.Session.isLogined &&
                      this.state.store !== "books" &&
                      this.state.storeItem.Identifier && (
                        <tr>
                          <th>{catalog.Profile.Identifier}</th>
                          <td> {this.state.storeItem.Identifier}</td>
                        </tr>
                      )}
                    {this.state.storeItem.DisplayDate && (
                      <tr>
                        <th>{catalog.Profile.DisplayDate}</th>
                        <td> {this.state.storeItem.DisplayDate}</td>
                      </tr>
                    )}
                    {this.state.storeItem.PropertyList1 && (
                      <tr>
                        <th>{catalog.Profile.PropertyList1}</th>
                        <td>
                          {(catalog.Config.PropertyList1 || []).map((i) => (
                            <>
                              {(
                                this.state.storeItem.PropertyList1 || []
                              ).includes(Number.parseInt(i.value)) && (
                                <>
                                  <Badge bg="dark">{i.label}</Badge>
                                </>
                              )}
                            </>
                          ))}
                        </td>
                      </tr>
                    )}
                    {Catalog.Session.isLogined &&
                      this.state.store !== "books" && (
                        <>
                          {this.state.storeItem.contact.email_address && (
                            <tr>
                              <th>{catalog.Profile.email_address}</th>
                              <td>
                                {" "}
                                {this.state.storeItem.contact.email_address}
                              </td>
                            </tr>
                          )}
                          {this.state.storeItem.contact.phone_number && (
                            <tr>
                              <th>{catalog.Profile.phone_number}</th>
                              <td>
                                {" "}
                                {this.state.storeItem.contact.phone_number}
                              </td>
                            </tr>
                          )}
                          {this.state.storeItem.contact.website_link && (
                            <tr>
                              <th>{catalog.Profile.website_link}</th>
                              <td>
                                {" "}
                                <a
                                  href={
                                    this.state.storeItem.contact.website_link
                                  }
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {this.state.storeItem.contact.website_link}
                                </a>{" "}
                              </td>
                            </tr>
                          )}
                          {this.state.storeItem.contact.address && (
                            <tr>
                              <th>{catalog.Profile.address}</th>
                              <td> {this.state.storeItem.contact.address}</td>
                            </tr>
                          )}
                        </>
                      )}

                    {Catalog.Session.isLogined &&
                      this.state.storeItem.Price && (
                        <tr>
                          <th>{catalog.Profile.Price}</th>
                          <td> {this.state.storeItem.Price}</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <div className="store-action">
                {Catalog.Session.isLogined ? (
                  <>
                    {this.state.store === "books" && (
                      <>
                        <Link
                          className="book-now-btn"
                          onClick={this.handleShow}
                        >
                          Book Now
                        </Link>
                        <Modal
                          show={this.state.modal}
                          onHide={this.handleClose}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {this.state.storeItem.Title}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <table>
                              <tbody>
                                {this.state.storeItem.Identifier && (
                                  <tr>
                                    <th>{catalog.Profile.Identifier}</th>
                                    <td> {this.state.storeItem.Identifier}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.DisplayDate && (
                                  <tr>
                                    <th>{catalog.Profile.DisplayDate}</th>
                                    <td> {this.state.storeItem.DisplayDate}</td>
                                  </tr>
                                )}
                                {this.state.storeItem.PropertyList1 && (
                                  <tr>
                                    <th>{catalog.Profile.PropertyList1}</th>
                                    <td>
                                      {(catalog.Config.PropertyList1 || []).map(
                                        (i) => (
                                          <>
                                            {(
                                              this.state.storeItem
                                                .PropertyList1 || []
                                            ).includes(
                                              Number.parseInt(i.value)
                                            ) && (
                                              <>
                                                <Badge bg="dark">
                                                  {i.label}
                                                </Badge>
                                              </>
                                            )}
                                          </>
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {Catalog.Session.isLogined &&
                                  this.state.storeItem.Price && (
                                    <tr>
                                      <th>{catalog.Profile.Price}</th>
                                      <td> {this.state.storeItem.Price}</td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={this.handleClose}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={this.handleSubmit}
                              disabled={this.state.isSubmit}
                            >
                              Confirm
                              {this.state.isSubmit && (
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              )}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link
                      to={"/login"}
                      className="book-now-btn"
                      onClick={() => {
                        Catalog.RediarectLogin = `/catalogs/${this.state.store}/${this.state.id}/${this.state.slug}`;
                      }}
                    >
                      {this.state.store === "books" ? (
                        <> Book Now</>
                      ) : (
                        <>Contact</>
                      )}
                    </Link>
                  </>
                )}
              </div>
            </div>
            {this.state.store !== "books" &&
              this.state.storeItem.gallery.length > 0 && (
                <div className="store-item-avathar">
                  <div className="store-item-main">
                    <TransitionGroup>
                      <CSSTransition
                        key={this.state.currentImageIndex}
                        timeout={500}
                        classNames="fade"
                      >
                        <img
                          src={this.state.currentimage}
                          alt={this.state.storeItem.Title}
                        />
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                  {this.state.storeItem.gallery.length > 1 && (
                    <>
                      <div className="store-item-gallery">
                        {this.state.storeItem.gallery.map((v, i) => {
                          return (
                            <div className="store-gallery-item" key={i}>
                              <img
                                src={v.image}
                                currentimage={v.image}
                                onClick={this.handleChangeCurrentImage}
                                alt={this.state.storeItem.Title}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
            <div
              className="store-item-content"
              dangerouslySetInnerHTML={{
                __html: this.state.storeItem.Detail1 || "",
              }}
            ></div>
          </div>
        </div>
      );
    } else {
      return <>Loading</>;
    }
  }
}
export default StoreItemDetailController;
